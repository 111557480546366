(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/form/assets/javascripts/form-data.js') >= 0) return;  svs.modules.push('/components/components/form/assets/javascripts/form-data.js');

var svs = svs || {};

(function(svs) {
  'use strict';
  const { loadVideo } = svs.components.youtube_video.utils;
  var log = svs.core.log.getLogger('components:form');

  svs.components.form.formData.fetch = function(id, finalCallback) {
    var formData = {};

    async.series(
      [
        fetchPlayerProperties,
        fetchFormById,
        fetchTermsDocumentById,
        fetchFormIsAnswered
      ],
      function(err) {
        if (err) {
          svs.components.MessageBox.show({
            title: err.title,
            message: err.message,
            type: svs.components.MessageBox.Type.ERROR,
            buttons: [{ text: 'Ok' }]
          });
        }
        finalCallback(err, formData);
      }
    );

    function fetchPlayerProperties(callback) {
      var userAlias = '';

      if (svs.core.userSession.hasRole(svs.core.userSession.roles.INTERNAL)) {
        userAlias = svs.components.Storage.browser.get(
          'internalAlias',
          'svs.components.login'
        );

        formData.playerProperties = {
          uFirstName: userAlias || 'Inloggad som administratör'
        };

        callback(null);
      } else if (svs.core.config.data.svsconfig.site === 'ombud') {
        var retailUrl = '/retailer/1/userprofile';
        fetchData({ method: 'GET', path: retailUrl }, function(err, data) {
          if (data && data.profile) {
            const retailerProperties = {
              uFirstName: data.profile.user.lmsInfo.firstName || data.profile.user.name,
              uLastName: data.profile.user.lmsInfo.lastName || '',
              verifiedStreet: data.profile.retailer.name.length ? data.profile.retailer.name[0] : '',
              verifiedCity: data.profile.retailer.name.length ? data.profile.retailer.name[1] : '',
              mobile: data.profile.user.lmsInfo.phone || data.profile.user.phone,
              email: data.profile.user.lmsInfo.email || '',
              retailer: true
            };
            formData.playerProperties = retailerProperties;
          }
          err && callback(err);
        });
        callback(null);
      } else {
        var playerCustSettingsApiVersion = svs.core.detect.tenant('tb') || svs.core.detect.tenant('mb') ? 2 : 1;
        var url = '/player/' + playerCustSettingsApiVersion + '/customizedsettings';

        fetchData({ method: 'GET', path: url }, function(err, data) {
          if (data && data.customizedSettings) {
            formData.playerProperties = data.customizedSettings.properties;

            var user = svs.core.userSession.getUser();
            formData.playerProperties.uFirstName = user.firstName || '';
            formData.playerProperties.uLastName = user.lastName || '';
          }
          callback(err);
        });
      }
    }

    function fetchFormById(callback) {
      fetchData(
        {
          method: 'GET',
          path: new svs.content.shared.ContentUrlBuilder('/content/2/id/' + id).build()
        },
        function(err, data) {
          if (data && Array.isArray(data.result) && data.result.length) {
            var result = data.result[0];

            formData = svs.components.form.utils.extend(formData, result);
            if (result.formItems) {
              formData.formItems = JSON.parse(result.formItems);
            }

            formData.images = parseImages(formData.images);
            formData.video = parseVideos(formData.videos);
            callback(null);
          } else {
            callback(err || {
              title: 'Innehållet är inte tillgängligt.',
              message: 'Vi kan tyvärr inte visa nästa steg för tillfället.'
            });
          }
        }
      );
    }

    function fetchTermsDocumentById(callback) {
      if (formData.formTermRelation && formData.formTermRelation.formTermId) {
        fetchData(
          {
            method: 'GET',
            path: new svs.content.shared.ContentUrlBuilder('/content/2/id/' + formData.formTermRelation.formTermId).build()
          },
          function(err, data) {
            if (data && Array.isArray(data.result) && data.result.length) {
              var result = data.result[0];
              if ((formData || {}).formTermRelation) {
                formData.formTermRelation.article = result;
              }
            }
            callback(err);
          }
        );
      } else {
        callback(null);
      }
    }

    function fetchFormIsAnswered(callback) {
      fetchData(
        {
          method: 'GET',
          path: new svs.content.shared.ContentUrlBuilder('/content/2/formisanswered/' + id).build()
        },
        function(err, data) {
          if (data && data.isAnswered && data.maxAnswersReached) {
            formData.submitted = dateFns.format(new Date(data.submitted), 'yyyy-MM-dd');
            formData.multipleAnswers = data.numberOfAnswers > 1;
          }
          callback(err);
        }
      );
    }
  };

  function parseImages(images) {
    if (images && images.length) {
      var parsedImages = {};
      for (var i = 0; i < images.length; i++) {
        var type = images[i].typeName;
        const { width, height } = images[i];
        const image = {
          ...images[i],
          aspectRatio: { width, height },
          cssSelectors: 'form-media-image'
        };

        parsedImages[type] = image;
      }
      return parsedImages;
    }
  }

  function parseVideos(videos) {
    if (videos && videos.length) {
      const video = videos[0];
      let videoModel;
      if (video.type === 'youtube') {
        loadVideo({
          type: 'youtube',
          videoId: video.id,
          title: video?.title || 'Svenska Spel Video',
          duration: video?.duration || null,
          description: video?.description || null,
          thumbnail: video?.thumbnail || null,
          publishedAt: video?.publishedAt || null
        }, (err, model) => {
          if (err) {
            log.warn(`Error loading youtube video: ${err.toString()}`);
          } else {
            videoModel = model;
          }
        });
      } else {
        videoModel = {
          type: 'solidtango',
          src:
          'https://' +
            svs.components.video_player.data.solidTangoUrl +
          '/widgets/embed/' +
          video.id +
          '?autoFillScreen=0',
          videoId: video.id,
          title: video?.title || 'Svenska Spel Video',
          duration: video?.duration || null,
          description: video?.description || null,
          thumbnail: video?.thumbnail || null,
          publishedAt: video?.publishedAt || null
        };
      }

      return videoModel;
    }
  }

  svs.components.form.formData.save = function(formData, callback) {
    formData.formItems = formData.formItems || [];
    var formQuestions = formData.formItems.filter(function(item) {
      return item.answer !== undefined;
    });

    var saveData = {
      formId: formData.id,
      formItems: formQuestions
    };

    var request = {
      method: 'POST',
      path: new svs.content.shared.ContentUrlBuilder('/content/2/formanswer').build(),
      data: JSON.stringify(saveData)
    };

    fetchData(request, function(err, data) {
      if (err) {
        svs.components.MessageBox.show({
          title: err.title,
          message: err.message,
          type: svs.components.MessageBox.Type.ERROR,
          buttons: [{ text: 'Ok' }]
        });
      }
      formData.submitted = dateFns.format(new Date(), 'yyyy-MM-dd');
      callback(err, data);
    });
  };

  function fetchData(request, callback) {
    svs.ui.loader.show();
    svs.core.jupiter.call(
      request,
      function(data) {
        svs.ui.loader.hide();
        callback(null, data);
      },
      function(xhr, textStatus, errorThrown) {
        var rsp = JSON.parse(xhr.responseText);
        var errorCode = (rsp.error || {}).code;
        var isAnsweredByUser = errorCode === 10102;

        svs.ui.loader.hide();
        log.error('Could not retrieve form. Error:' + errorThrown);
        callback({
          title: isAnsweredByUser ? 'Du deltar redan' : 'Något gick fel',
          message: isAnsweredByUser ? 'Du kan bara delta en gång.' : errorThrown
        });
      }
    );
  }
})(svs);


 })(window);